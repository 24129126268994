export default {
  // GREEN
  green0: '#05A660',
  green1: '#06C270',
  green2: '#39D98A',
  green3: '#57EBA1',
  green4: '#E3FFF1',

  // YELLOW
  yellow0: '#E5B800',
  yellow1: '#FFCC00',
  yellow2: '#FDDD48',
  yellow3: '#FDED72',
  yellow4: '#FFFEE5',

  // ORANGE
  orange0: '#E57A00',
  orange1: '#FF8800',
  orange2: '#FDAC42',
  orange3: '#FCCC75',
  orange4: '#FFF8E5',

  // RED
  red0: '#E53535',
  red1: '#FF3B3B',
  red2: '#FF5C5C',
  red3: '#FF8080',
  red4: '#FFE5E5',
  red6: '#891118',

  // DARK
  dark0: '#111122',
  dark1: '#424259',
  dark2: '#878899',
  dark3: '#B3B4C1',
  dark4: '#C7C9D9',

  // LIGHT
  light0: '#E4E4EB',
  light1: '#EBEBF0',
  light2: '#F2F2F5',
  light3: '#F8F8FA',
  light4: '#FFFFFF',

  // BLUE
  blue0: '#004FC4',
  blue1: '#0063F7',
  blue2: '#5B8DEF',
  blue3: '#9DBFF9',
  blue4: '#E5F0FF',

  // GOLD
  gold1: '#FFF3E1',
  gold8: '#644203',

  // PRIMARY
  primaryDarker: '#AD080E',
  mainColor: '#D31F26',
  primarySofter: '#FF6969',
  primaryLighter: '#FF989C',
  primarySubtle: '#FFD6D7',

  yellowDarker: '#C29C33',
  yellowPerfect: '#F9C63B',
  yellowSofter: '#FDDD48',
  yellowLighter: '#FDED72',
  yellowSubtle: '#FFFEE5',

  greenDarker: '#1F8A5B',
  greenPerfect: '#06C270',
  greenSofter: '#39D98A',
  greenLighter: '#57EBA1',
  greenSubtle: '#E3FFF1',
  cadmiumGreen: '#08663F',

  naturalDarker: '#383838',
  naturalGrey: '#727272',
  naturalSofter: '#C1C1C1',
  naturalLighter: '#F8F8F8',
  naturalWhite: '#FFFFFF',

  white: '#ffffff',
  black: '#000000',
  grey: '#C7C7C7',
  borderGrey: '#D9D9D9;',

  goldenPurple1: '#F9F0FF',
  goldenPurple6: '#722ED1',

  primary1: '#E6F7FF',
  primary6: '#1890FF',
};

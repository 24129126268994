import React, { lazy } from 'react';
import AUTH_URL from '../configs/url/auth';
import PRINT_URL from '../configs/url/print';

const Auth = lazy(() => {
  return import('./auth');
});

const WithHeaderPage = lazy(() => {
  return import('./withHeader');
});

const PrintPage = lazy(() => {
  return import('./print');
});

const general = [
  // public Routes
  {
    path: AUTH_URL.MAIN,
    component: <Auth />,
    isPublic: true,
  },

  // private routes
  {
    path: PRINT_URL.MAIN,
    component: <PrintPage />,
    isPublic: false,
  },
  {
    path: '/*',
    component: <WithHeaderPage />,
    isPublic: false,
  },
];

export default { general };

import { urlBuilder } from '../../utils/route';

const prints = {
  MAIN: '/print/*',
  ORDER_ID: {
    MAIN: '/order/:orderId',
  },
  SPK_ID: {
    MAIN: '/spk/:orderId',
  },
};

export default prints;

export const printsURL = () => {
  return new Proxy(prints, urlBuilder('/print'));
};

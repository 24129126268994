import BaseService from '../baseServices';
import REST from '../../configs/rest';

function putProductStyleV2({ productId, body }) {
  return BaseService.put(REST.PRODUCT_V2_ADMIN_STYLE(productId), body);
}

export default {
  putProductStyleV2,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';

function getProductMetaTag({ productId }) {
  return BaseService.get(REST.PRODUCT_META_TAG(productId));
}

function createProductMetaTag({ productId, body }) {
  return BaseService.post(REST.CREATE_PRODUCT_META_TAG(productId), body);
}

function putProductMetaTag({ productId, metaTagId, body }) {
  return BaseService.put(REST.PRODUCT_META_TAG_ID(productId, metaTagId), body);
}

export default {
  getProductMetaTag,
  createProductMetaTag,
  putProductMetaTag,
};

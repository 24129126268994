import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getProductImages(params) {
  return BaseService.get(REST.PRODUCT_IMAGES, {
    params: filterParams(params),
  });
}

function putProductImage({ id, body }) {
  const payload = {
    product_id: id,
    product_images: body,
  };

  return BaseService.put(REST.PRODUCT_IMAGES, payload);
}

function postProductImage(body) {
  return BaseService.post(REST.PRODUCT_IMAGES, body);
}

function deleteProductImage({ body, id }) {
  const payload = {
    product_id: id,
    product_images: body,
  };

  return BaseService.delete(REST.PRODUCT_IMAGES, {
    data: payload,
  });
}

export default {
  getProductImages,
  putProductImage,
  postProductImage,
  deleteProductImage,
};

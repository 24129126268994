import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
// import 'antd/dist/antd.less';
import 'antd/dist/antd.min.css';
import AppWrapper from './App.style';
import Pages from './pages';
import './index.css';
// import { browserNotification } from './utils/notification';
// import { logoSmall } from './assets';

// import ReactGA from 'react-ga';

const App = () => {
  // ReactGA.initialize(process.env.REACT_APP_GA);
  // ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <HelmetProvider>
      <AppWrapper>
        <Pages />
      </AppWrapper>
    </HelmetProvider>
  );
};

export default App;

import BaseService from '../baseServices';
import REST from '../../configs/rest';

function getReferralAvailability({ code }) {
  return BaseService.get(REST.REFERRAL_AVAILABILITY, {
    params: {
      code,
    },
  });
}

export default {
  getReferralAvailability,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListRoles(params) {
  return BaseService.get(REST.ROLE, {
    params: filterParams(params),
  });
}

export default {
  getListRoles,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getProductRingSizes(params) {
  return BaseService.get(REST.PRODUCT_RING_SIZES, {
    params: filterParams(params),
  });
}

function putProductRingSize({ id, body }) {
  const payload = {
    product_id: id,
    sizes: body,
  };

  return BaseService.put(REST.PRODUCT_RING_SIZES, payload);
}

function postProductRingSize(body) {
  return BaseService.post(REST.PRODUCT_RING_SIZES, body);
}

function deleteProductRingSize({ body, id }) {
  const payload = {
    product_id: id,
    sizes: body,
  };

  return BaseService.delete(REST.PRODUCT_RING_SIZES, {
    data: payload,
  });
}

export default {
  getProductRingSizes,
  putProductRingSize,
  postProductRingSize,
  deleteProductRingSize,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function postTag(body) {
  return BaseService.post(REST.TAG, body);
}

function getListTag(params) {
  return BaseService.get(REST.TAG, {
    params: filterParams(params),
  });
}

export default {
  postTag,
  getListTag,
};

import axios from 'axios';
import { getCookie, setCookie } from '../../utils/cookies';
import BaseService from '../baseServices';
import REST from '../../configs/rest';
import URL from '../../configs/baseUrl';
import COOKIES_STORAGE from '../../configs/cookiesStorage';

const { COOKIES } = COOKIES_STORAGE;

function getTokenAuth() {
  if (
    getCookie(COOKIES.TOKEN) !== '' &&
    getCookie(COOKIES.TOKEN) !== 'undefined'
  ) {
    return JSON.parse(getCookie(COOKIES.TOKEN));
  }
  return '';
}

function getDeviceToken() {
  if (getCookie(COOKIES.DEVICE_TOKEN)) {
    return JSON.parse(getCookie(COOKIES.DEVICE_TOKEN));
  }
  return '';
}

function getUserId() {
  if (getCookie(COOKIES.USER_DATA) !== '') {
    return JSON.parse(getCookie(COOKIES.USER_DATA))?.id;
  }
  return '';
}

function getUserRole() {
  if (getCookie(COOKIES.USER_DATA) !== '') {
    return JSON.parse(getCookie(COOKIES.USER_DATA))?.role;
  }
  return '';
}

function getUserData() {
  if (
    getCookie(COOKIES.USER_DATA) !== '' &&
    getCookie(COOKIES.USER_DATA) !== 'undefined'
  ) {
    return JSON.parse(getCookie(COOKIES.USER_DATA));
  }
  return '';
}
// is user is logged in
function isUserAuthenticated() {
  return getTokenAuth() !== '' && getUserData() !== '';
}

function isUserAuthenticatedRedirect() {
  if (isUserAuthenticated()) {
    window.location.replace(URL.USERS);
  }
  return '';
}

function postLogin(body) {
  return BaseService.post(REST.LOGIN, body);
}

function logout() {
  localStorage.clear();
  setCookie(COOKIES.USER_DATA, '');
  setCookie(COOKIES.TOKEN, '');
  window.location.replace(URL.LOGIN);
}

function postRegister(body) {
  return BaseService.post(REST.REGISTER, body);
}

function postForgotPassword(body) {
  return BaseService.post(REST.FORGOT_PASSWORD, body);
}

function postNewPassword(body) {
  return BaseService.post(REST.NEW_PASSWORD, body);
}

function postEmailAvailability(body) {
  const url = `${process.env.REACT_APP_REST_URL}${REST.EMAIL_AVAILABILITY}`;
  return axios.post(url, body);
}

function postPhoneNumberAvailability(body) {
  const url = `${process.env.REACT_APP_REST_URL}${REST.PHONE_NUMBER_AVAILABILITY}`;
  return axios.post(url, body);
}

function switchTeam(body) {
  return BaseService.post(REST.SWITCH_TEAM, body);
}

function postLogout(body) {
  return BaseService.post(REST.LOGOUT, body);
}

function postAdminRegisterUser(body) {
  return BaseService.post(REST.ADMIN_REGISTER_USER, body);
}

export default {
  isUserAuthenticated,
  getTokenAuth,
  getUserData,
  getUserId,
  postLogin,
  logout,
  postRegister,
  postForgotPassword,
  postNewPassword,
  postEmailAvailability,
  postPhoneNumberAvailability,
  isUserAuthenticatedRedirect,
  getUserRole,
  switchTeam,
  postLogout,
  getDeviceToken,
  postAdminRegisterUser,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListProductsV2(params) {
  return BaseService.get(REST.PRODUCT_V2_ADMIN, {
    params: filterParams(params),
  });
}

function getProductV2ById(productId) {
  return BaseService.get(REST.PRODUCT_V2_ADMIN_ID(productId));
}

export default {
  getListProductsV2,
  getProductV2ById,
};

import { updateSorter, filterEmptyObject, isObjectEmpty } from './commonUtils';
import { defaultSortUpdated } from '../configs/sorting';

// eslint-disable-next-line import/prefer-default-export
export const filterParams = (params) => {
  const query = {
    limit: params?.limit,
    offset: params?.offset,
    filter: {},
  };

  if (params?.keyword) {
    query.filter = {
      ...query.filter,
      name: {
        ilike: `%${params?.keyword}%`,
      },
    };
  }

  if (params?.filter) {
    query.filter = {
      ...query.filter,
      ...params?.filter,
    };
  }

  if (params?.order) {
    query.order = JSON.stringify(
      updateSorter(defaultSortUpdated, params.order)
    );
  }

  if (params?.order === null) {
    query.order = JSON.stringify([defaultSortUpdated]);
  }

  if (Object.keys(query.filter)?.length === 0) {
    delete query?.filter;
  }

  if (params?.filter || query?.filter) {
    const isFilterEmpty = isObjectEmpty(filterEmptyObject(params?.filter));
    if (isFilterEmpty) {
      delete query?.filter;
    }
  }

  if (params?.limit === undefined) {
    delete query?.limit;
  }

  if (params?.offset === undefined) {
    delete query?.offset;
  }

  return query;
};

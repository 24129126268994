import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getProductGemStones(params) {
  return BaseService.get(REST.PRODUCT_GEM_STONE, {
    params: filterParams(params),
  });
}

function putProductGemStone({ id, body }) {
  const payload = {
    product_id: id,
    gem_stones: body,
  };

  return BaseService.put(REST.PRODUCT_GEM_STONE, payload);
}

function postProductGemStone(body) {
  return BaseService.post(REST.PRODUCT_GEM_STONE, body);
}

function deleteProductGemStone({ body, id }) {
  const payload = {
    product_id: id,
    gem_stones: body,
  };

  return BaseService.delete(REST.PRODUCT_GEM_STONE, {
    data: payload,
  });
}

export default {
  getProductGemStones,
  putProductGemStone,
  postProductGemStone,
  deleteProductGemStone,
};

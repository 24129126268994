import REST from '../../configs/rest';
import BaseService from '../baseServices';

function postProductGroupImage(body) {
  return BaseService.post(REST.PRODUCT_GROUP_IMAGE, body);
}

function putProductGroupImage({ body, id }) {
  return BaseService.put(REST.PRODUCT_GROUP_IMAGE_ID(id), body);
}

function deleteProductGroupImage(id) {
  return BaseService.delete(REST.PRODUCT_GROUP_IMAGE_ID(id));
}

export default {
  postProductGroupImage,
  putProductGroupImage,
  deleteProductGroupImage,
};

// eslint-disable-next-line import/prefer-default-export
export const defaultSort = {
  attr: 'created_at',
  order: 'desc',
};

export const defaultSortUpdated = {
  attr: 'updated_at',
  order: 'desc',
};

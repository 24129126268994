import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListMerchants(params) {
  return BaseService.get(REST.MERCHANT, {
    params: filterParams(params),
  });
}

function getMerchantById(id) {
  return BaseService.get(REST.MERCHANT_ID(id));
}

function putMerchantById({ id, body }) {
  return BaseService.put(REST.MERCHANT_ID(id), body);
}

function putMerchantOwner({ body }) {
  return BaseService.put(REST.MERCHANT_OWNER, body);
}

export default {
  getListMerchants,
  getMerchantById,
  putMerchantById,
  putMerchantOwner,
};

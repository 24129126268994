import BaseService from '../baseServices';
import REST from '../../configs/rest';

function putProductDiamondShapeV2({ productId, body }) {
  return BaseService.put(REST.PRODUCT_V2_ADMIN_DIAMOND_SHAPE(productId), body);
}

export default {
  putProductDiamondShapeV2,
};

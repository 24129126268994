import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListUsers(params) {
  return BaseService.get(REST.USER, {
    params: filterParams(params),
  });
}

function getUserExport(params) {
  return BaseService.get(REST.USER_EXPORT, {
    params: filterParams(params),
  });
}

function getUserDetail(id) {
  return BaseService.get(REST.USER_ID(id));
}

function putUserStatus({ userId, status }) {
  return BaseService.put(REST.USER_STATUS, {
    user_id: userId,
    status,
  });
}

function putUserRole({ userId, roleId }) {
  return BaseService.put(REST.USER_ROLE, {
    user_id: userId,
    role_id: roleId,
  });
}

export default {
  getListUsers,
  getUserDetail,
  putUserStatus,
  putUserRole,
  getUserExport,
};

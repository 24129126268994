import styled from 'styled-components';
import color from './configs/color';

const AppWrapper = styled.div`
  min-height: 100vh;
  font-family: Poppins;
  background-color: ${color.light3};
  min-width: 1000px;

  body {
    margin: 0;
    font-family: Poppins;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  //font style

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-bottom: 0rem;
    font-family: 'Cormorant';
    line-height: 5.4375rem;
  }
  h1 {
    font-size: 2.86rem;
    line-height: 1.3;
  }
  h2 {
    font-size: 2.3rem;
    line-height: 1.3;
  }

  h3 {
    font-size: 2rem;
    line-height: 1.3;
  }

  h4 {
    font-size: 1.7rem;
    line-height: 1.3;
  }

  h5 {
    font-size: 1.4rem;
    line-height: 1.4;
  }

  h6 {
    font-size: 1.14rem;
    line-height: 1.4;
  }

  // color style

  .active {
    background-color: unset;
  }

  .times-new-roman {
    font-family: 'Cormorant';
  }

  .mainColor {
    color: ${color.mainColor};
  }
  .dark0 {
    color: ${color.dark0};
  }
  .dark1 {
    color: ${color.dark1};
  }
  .dark2 {
    color: ${color.dark2};
  }
  .dark3 {
    color: ${color.dark3};
  }
  .dark4 {
    color: ${color.dark4};
  }
  .green0 {
    color: ${color.green0};
  }
  .green1 {
    color: ${color.green1};
  }
  .red0 {
    color: ${color.red0};
  }
  .red1 {
    color: ${color.red1};
  }
  .red2 {
    color: ${color.red2};
  }
  .orange {
    color: ${color.orange1};
  }
  .blue1 {
    color: ${color.blue1};
  }
  .light1 {
    color: ${color.light1};
  }
  .light2 {
    color: ${color.light2};
  }
  .light3 {
    color: ${color.light3};
  }
  .light4 {
    color: ${color.light4};
  }
  .black {
    color: ${color.black};
  }
  .naturalDarker {
    color: ${color.naturalDarker};
  }
  .cadmiumGreen {
    color: ${color.cadmiumGreen};
  }
  .anticon {
    &.purple {
      color: ${color.purple1};
      background-color: ${color.grey3};
    }
    &.orange {
      color: ${color.orange1};
      background-color: ${color.orange6};
    }
  }

  .ant-avatar {
    background: ${color.blue2};
  }
  .capitalize {
    text-transform: capitalize;
  }
  .underline {
    text-decoration: underline;
  }
  .naturalGrey {
    color: ${color.naturalGrey};
  }

  .ck-editor__editable_inline {
    min-height: 200px;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }
`;

export default AppWrapper;

export default {
  COOKIES: {
    USER_DATA: 'userData',
    TOKEN: 'token',
  },
  LOCAL_STORAGE: {
    USER_RULES: 'userRules',
    TEAM_ROLE: 'teamRole',
  },
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';

function postOrder({ body }) {
  return BaseService.post(REST.ADMIN_ORDER, body);
}

function getOrderPayment({ orderId }) {
  return BaseService.get(REST.ADMIN_ORDER_PAYMENT(orderId));
}

function postOrderSplitPayment({ orderId, body }) {
  return BaseService.post(REST.ADMIN_ORDER_PAYMENT_SPLIT(orderId), body);
}

function putRegeneratePaymentLink({ orderId, paymentId }) {
  return BaseService.put(
    REST.ADMIN_ORDER_REGENERATE_PAYMENT({
      orderId,
      paymentId,
    })
  );
}

export default {
  postOrder,
  getOrderPayment,
  postOrderSplitPayment,
  putRegeneratePaymentLink,
};

const rest = {
  // auth
  LOGIN: '/login',
  LOGOUT: '/auth/logout',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/password:request',
  NEW_PASSWORD: '/auth/password:submit',
  EMAIL_AVAILABILITY: '/auth/email:available',
  PHONE_NUMBER_AVAILABILITY: '/auth/phone-number:available',
  SWITCH_TEAM: '/auth/switchteam',
  CHANGE_PASSWORD: (userId) => {
    return `/users/${userId}/password-change`;
  },
  ADMIN_REGISTER_USER: '/admin/register',

  // files
  UPLOAD_REQUEST: '/upload',

  // users
  USER: '/user',
  USER_ID: (id) => {
    return `/user/${id}`;
  },
  USER_STATUS: '/user-status',
  USER_ROLE: '/user-role',
  USER_EXPORT: '/user-export',

  // roles
  ROLE: '/role',

  // banners
  BANNER: '/banner',
  BANNER_ID: (id) => {
    return `/banner/${id}`;
  },

  // voucher
  VOUCHER: '/voucher',
  VOUCHER_ID: (id) => {
    return `/voucher/${id}`;
  },
  VOUCHER_CODE_AVAILABILITY: 'voucher-available-code',

  // merchants
  MERCHANT: '/merchant',
  MERCHANT_ID: (id) => {
    return `/merchant/${id}`;
  },
  MERCHANT_OWNER: '/merchant-owner',

  // products
  PRODUCT_ALL: '/product-all',
  PRODUCT: '/product',
  PRODUCT_ID: (id) => {
    return `/product/${id}`;
  },
  PRODUCT_IMAGES: '/product-image',
  PRODUCT_RING_SIZES: '/size',
  PRODUCT_RING_DIAMOND_SHAPE: '/ring-diamond-shape',
  PRODUCT_TAG: '/product_tag',
  PRODUCT_GEM_STONE: '/gem-stone',
  COUNT_PRODUCT_WISHLIST: (id) => {
    return `/wishlist-count/${id}`;
  },

  // product v2
  PRODUCT_V2_ADMIN: '/v2/admin/product',
  PRODUCT_V2_ADMIN_ID: (productId) => {
    return `/v2/admin/product/${productId}`;
  },
  PRODUCT_V2_ADMIN_PRODUCT_TYPE: (productId) => {
    return `/v2/admin/product/${productId}/product-type`;
  },
  PRODUCT_V2_ADMIN_DIAMOND_SHAPE: (productId) => {
    return `/v2/admin/product/${productId}/diamond-shape`;
  },
  PRODUCT_V2_ADMIN_STYLE: (productId) => {
    return `/v2/admin/product/${productId}/style`;
  },

  // product meta tag
  PRODUCT_META_TAG: (productId) => {
    return `/product/${productId}/meta-tag`;
  },
  CREATE_PRODUCT_META_TAG: (productId) => {
    return `/admin/product/${productId}/meta-tag`;
  },
  PRODUCT_META_TAG_ID: (productId, metaTagId) => {
    return `/admin/product/${productId}/meta-tag/${metaTagId}`;
  },

  // tag
  TAG: '/tag',
  BANNER_TAG: '/banner-tag',

  // order
  ORDER: '/order-admin',
  ORDER_ID: (id) => {
    return `/order-admin/${id}`;
  },
  DECLINE_ORDER: '/decline-order',
  PROCESS_ORDER: '/process-order',
  SHIP_ORDER: '/ship-order',
  ORDER_DELIVER: '/order-deliver',
  ORDER_READY: '/ready-order',
  ORDER_NOTES: (id) => {
    return `/order/${id}/admin-notes`;
  },
  ORDER_RE_GENERATE_URL: (id) => {
    return `/admin/order/${id}/payment-link`;
  },

  // admin order
  ADMIN_ORDER: '/admin/order',
  ADMIN_ORDER_PAYMENT: (orderId) => {
    return `/admin/order/${orderId}/payments`;
  },
  ADMIN_ORDER_PAYMENT_SPLIT: (orderId) => {
    return `/order/${orderId}/payments/split`;
  },
  ADMIN_ORDER_REGENERATE_PAYMENT: ({ orderId, paymentId }) => {
    return `/admin/order/${orderId}/payments/${paymentId}/payment-link`;
  },

  // shipping
  CHECK_SHIPPING_SERVICE: '/check-shipping-price',
  SHIP_ORDER_PARTNER: '/ship-order-partner',

  // shipping JNE Service
  PROVINCE_DESTINATION: '/jne-destination-code/province',
  CITY_DESTINATION: '/jne-destination-code/city',
  DISTRICT_DESTINATION: '/jne-destination-code/district',
  SUBDISTRICT_DESTINATION: '/jne-destination-code/subdistrict',
  GET_DESTINATION_CODE: '/jne-destination-code',
  CHECK_SHIPPING_PRICE_JNE: '/check-shipping-price-jne',
  MAKE_SHIPPING_ORDER_JNE: '/make-shipping-order',
  TRACK_SHIPPING_HISTORY_JNE: (id) => {
    return `/jne-track-shipping/${id}`;
  },

  // product group
  PRODUCT_GROUP_ALL: '/group-all',
  PRODUCT_GROUP: '/group',
  PRODUCT_GROUP_ID: (id) => {
    return `/group/${id}`;
  },

  PRODUCT_GROUP_ITEM: '/group-product',
  PRODUCT_GROUP_ITEM_ID: (id) => {
    return `/group-product/${id}`;
  },

  PRODUCT_GROUP_IMAGE: '/group-image',
  PRODUCT_GROUP_IMAGE_ID: (id) => {
    return `/group-image/${id}`;
  },

  // SPK
  SPK: '/admin/spk',
  ORDER_SPK: (orderId, productSoldId) => {
    return `/order-admin/${orderId}/product-sold/${productSoldId}/spk`;
  },
  SPK_IMAGE: (orderId, productSoldId) => {
    return `/order-admin/${orderId}/product-sold/${productSoldId}/spk/images`;
  },

  // try at home
  TRY_AT_HOME: '/admin/tryathome/orders',

  // referral code checker
  REFERRAL_AVAILABILITY: '/referral-availability',
};

export default rest;

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListTryAtHomeOrder(params) {
  return BaseService.get(REST.TRY_AT_HOME, {
    params: filterParams(params),
  });
}

export default {
  getListTryAtHomeOrder,
};

export default {
  // public
  COMING: '/coming-soon',
  TOS: '/term-of-service',
  PRIVACYPOLICY: '/privacy-policy',

  LANDING: '/',
  COMPONENTS: '/components',

  // Auth
  AUTH: '/auth/*',
  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  FORGOT_PASSWORD: '/auth/forgot-password',
  NEW_PASSWORD: '/auth/new-password',

  // ADMIN
  ADMIN: '/admin/*',
  USERS: '/admin/users',
  TEAMS: '/admin/teams',
  LICENSES: '/admin/licenses',
  PAYMENTS: '/admin/payments',
  TRANSACTIONS: '/admin/transactions',

  NOTIFICATIONS: '/notifications/*',
  // accounts
  ACCOUNT: '/account/*',
  ACCOUNT_PASSWORD: '/account/password',
};

import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';
import BaseService from '../baseServices';

function postProductGroup(body) {
  return BaseService.post(REST.PRODUCT_GROUP, body);
}

function putProductGroup({ id, body }) {
  return BaseService.put(REST.PRODUCT_GROUP_ID(id), body);
}

function getProductGroupList(params) {
  return BaseService.get(REST.PRODUCT_GROUP_ALL, {
    params: filterParams(params),
  });
}

function getProductGroupById(id) {
  return BaseService.get(REST.PRODUCT_GROUP_ID(id));
}

export default {
  postProductGroup,
  getProductGroupList,
  getProductGroupById,
  putProductGroup,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListProducts(params) {
  return BaseService.get(REST.PRODUCT_ALL, {
    params: filterParams(params),
  });
}

function getProductById(id) {
  return BaseService.get(REST.PRODUCT_ID(id));
}

function putProductById({ id, body }) {
  return BaseService.put(REST.PRODUCT_ID(id), body);
}

function postProduct(body) {
  return BaseService.post(REST.PRODUCT, body);
}

function getProductCountWishList(id) {
  return BaseService.get(REST.COUNT_PRODUCT_WISHLIST(id));
}

export default {
  getListProducts,
  getProductById,
  putProductById,
  postProduct,
  getProductCountWishList,
};

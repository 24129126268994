import REST from '../../configs/rest';
import BaseService from '../baseServices';
import { filterParams } from '../../utils/request';

function getListOrder(params) {
  return BaseService.get(REST.ORDER, {
    params: filterParams(params),
  });
}

function postOrder(body) {
  return BaseService.post(REST.ORDER, body);
}

function getOrderById(id) {
  return BaseService.get(REST.ORDER_ID(id));
}

function postDeclineOrder({ orderId, decliningNotes }) {
  const body = {
    order_id: orderId,
    declining_notes: decliningNotes ?? '',
  };
  return BaseService.post(REST.DECLINE_ORDER, body);
}

function postProcessOrder({ orderId }) {
  const body = {
    order_id: orderId,
  };
  return BaseService.post(REST.PROCESS_ORDER, body);
}

function postShipOrder({ orderId }) {
  const body = {
    order_id: orderId,
  };
  return BaseService.post(REST.SHIP_ORDER, body);
}

function postOrderToDone({ orderId }) {
  const body = {
    order_id: orderId,
  };
  return BaseService.post(REST.ORDER_DELIVER, body);
}

function postOrderToReady({ orderId }) {
  const body = {
    order_id: orderId,
  };
  return BaseService.post(REST.ORDER_READY, body);
}

function putAdminNotes({ orderId, body }) {
  return BaseService.put(REST.ORDER_NOTES(orderId), body);
}

function putRegenerateUrl({ orderId }) {
  return BaseService.put(REST.ORDER_RE_GENERATE_URL(orderId));
}

export default {
  getListOrder,
  getOrderById,
  postOrder,
  postDeclineOrder,
  postProcessOrder,
  postShipOrder,
  postOrderToDone,
  postOrderToReady,
  putAdminNotes,
  putRegenerateUrl,
};

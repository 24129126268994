import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListSPK(params) {
  return BaseService.get(REST.SPK, {
    params: filterParams(params),
  });
}

function getOrderSPK({ orderId, productSoldId }) {
  return BaseService.get(REST.ORDER_SPK(orderId, productSoldId));
}

function putOrderSPK({ orderId, productSoldId, body }) {
  return BaseService.put(REST.ORDER_SPK(orderId, productSoldId), body);
}

function postOrderSPK({ orderId, productSoldId, body }) {
  return BaseService.post(REST.ORDER_SPK(orderId, productSoldId), body);
}

function postSPKImages({ orderId, productSoldId, body }) {
  return BaseService.post(REST.SPK_IMAGE(orderId, productSoldId), body);
}

function getSPKImages({ orderId, productSoldId }) {
  return BaseService.get(REST.SPK_IMAGE(orderId, productSoldId));
}

function deleteSPKImages({ orderId, productSoldId }) {
  return BaseService.delete(REST.SPK_IMAGE(orderId, productSoldId));
}

export default {
  getListSPK,
  getOrderSPK,
  putOrderSPK,
  postOrderSPK,
  getSPKImages,
  deleteSPKImages,
  postSPKImages,
};

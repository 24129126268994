import BaseService from '../baseServices';
import REST from '../../configs/rest';

function getListProvince() {
  return BaseService.get(REST.PROVINCE_DESTINATION);
}

function getListCity(params) {
  return BaseService.get(REST.CITY_DESTINATION, {
    params,
  });
}

function getListDistrict(params) {
  return BaseService.get(REST.DISTRICT_DESTINATION, {
    params,
  });
}

function getListSubDistrict(params) {
  return BaseService.get(REST.SUBDISTRICT_DESTINATION, {
    params,
  });
}

function postListJNEDestinationCode(body) {
  return BaseService.post(REST.CHECK_SHIPPING_PRICE_JNE, body);
}

function postMakeShippingOrder(body) {
  return BaseService.post(REST.MAKE_SHIPPING_ORDER_JNE, body);
}

function getTrackingHistory(id) {
  return BaseService.get(REST.TRACK_SHIPPING_HISTORY_JNE(id));
}

export default {
  getListProvince,
  getListCity,
  getListDistrict,
  getListSubDistrict,
  postListJNEDestinationCode,
  postMakeShippingOrder,
  getTrackingHistory,
};

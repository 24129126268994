import { message } from 'antd';
import commonMessage from '../configs/commonMessage';

export const timeoutFunc = (timeout, action, delay) => {
  clearTimeout(timeout);
  return setTimeout(() => {
    action();
  }, delay);
};

export const getNickName = (name) => {
  if (name) {
    return name[0].toUpperCase() + name[name.length - 1].toUpperCase();
  }
  const x = name?.match(/\b\w/g) || [];
  return ((x.shift() || '') + (x.pop() || '')).toUpperCase();
};

export function numberFormatter(x) {
  if (x !== null && x !== undefined) {
    return x
      .toString()
      .replace(/\./g, ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return x;
}

export function toRupiah(x) {
  if (x) {
    return `Rp ${numberFormatter(x)}`;
  }
  return '';
}

export function toIDR(x) {
  if (!Number.isNaN(x)) {
    return `IDR ${numberFormatter(x)}`;
  }
  return undefined;
}

export const isObjectEmpty = (value) => {
  return (
    (Object.prototype.toString.call(value) === '[object Object]' &&
      JSON.stringify(value) === '{}') ||
    !value
  );
};

export const byteToSize = (bytes, decimals = 0) => {
  if (bytes === 0) return '0 Bytes';
  const formatted = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const calculate = Math.floor(Math.log(bytes) / Math.log(formatted));

  return `${parseFloat((bytes / formatted ** calculate).toFixed(dm))} ${
    sizes[calculate]
  }`;
};

export const hzToSize = (hz, decimals = 0) => {
  if (hz === 0) return '0 Hz';
  const formatted = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['MHz', 'GHz'];
  const calculate = Math.floor(Math.log(hz) / Math.log(formatted));

  return `${parseFloat((hz / formatted ** calculate).toFixed(dm))} ${
    sizes[calculate]
  }`;
};

export const convertByteMetric = (value, origin, dest) => {
  const metric = ['Byte', 'KB', 'MB', 'GB', 'TB'];
  const multiplier = [1, 1000, 1000000, 1000000000, 1000000000000];
  const shifter =
    multiplier[metric.indexOf(origin)] / multiplier[metric.indexOf(dest)];
  return value * shifter;
};

export const sortTable = (sorter) => {
  const sortData = sorter.constructor === Object ? [sorter] : sorter;
  if (sortData.length === 1 && !sortData[0].order) return null;
  const initialValue = {};
  sortData.sort().forEach((item) => {
    const key = item.field;
    const value = item.order === 'ascend' ? 'asc' : 'desc';
    initialValue[key] = value;
  });
  return initialValue;
};

export const sortVariables = (sort) => {
  const orderDict = {
    ascend: 'asc',
    descend: 'desc',
  };
  const initialValue = {};
  if (Array.isArray(sort)) {
    sort.forEach((a) => {
      Object.assign(initialValue, {
        [a.field]: orderDict[a.order],
      });
    });
  } else if (sort?.field && sort?.order) {
    Object.assign(initialValue, {
      [sort.field]: orderDict[sort.order],
    });
  } else {
    return undefined;
  }
  return initialValue;
};

export const mappingOrderBy = (sort) => {
  const orderDict = {
    ascend: 'asc',
    descend: 'desc',
  };
  if (Array.isArray(sort)) {
    const initialValue = sort.map((a) => {
      return {
        attr: a.field,
        order: orderDict[a.order],
      };
    });
    return initialValue;
  }
  if (sort?.field && sort?.order) {
    const initialValue = {};
    Object.assign(initialValue, {
      attr: sort.field,
      order: orderDict[sort.order],
    });
    return [initialValue];
  }
  return undefined;
};

export const updateSorter = (defaultSort, sort) => {
  if (mappingOrderBy(sort)) {
    return mappingOrderBy(sort);
  }
  return [defaultSort];
};

export const filterEmptyObject = (value) => {
  const data = {};
  Object.keys(value).forEach((key) => {
    if (value[key] && key !== '') {
      data[key] = value[key];
    }
  });
  return data;
};

export const validateEmail = (email) => {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const onInputNumber = (event) => {
  if (
    !/[0-9]/.test(event.key) &&
    event.key !== 'Backspace' &&
    event.key !== '.' &&
    event.key !== 'Tab'
  ) {
    event.preventDefault();
  }
};

export const getLastSubId = (id = '') => {
  const splittedId = id.split('-');
  return splittedId[4];
};

export const compareTwoArrays = ({ array1, array2 }) => {
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every((value, index) => {
        return value === array2Sorted[index];
      })
  );
};

export const pluralWriter = (value, word) => {
  if (value > 1) {
    return `${word}s`;
  }
  return word;
};

export const isValidURL = (url) => {
  const res = url?.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const responseFormatter = (params) => {
  if (params?.length === 0 || params === undefined || params === null) {
    return '-';
  }
  return params;
};

export const numberFormRules = (name) => {
  return {
    validator(_, value) {
      const numberPattern = /\d+/g;
      if (value?.length > 1) {
        if (!numberPattern.test(value)) {
          return Promise.reject(new Error(commonMessage.invalidType(name)));
        }
      }
      return Promise.resolve();
    },
  };
};

export const decimalFormRules = (name) => {
  return {
    validator(_, value) {
      const numberPattern = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
      if (value?.length > 1) {
        if (!numberPattern.test(value)) {
          return Promise.reject(new Error(commonMessage.invalidType(name)));
        }
      }
      return Promise.resolve();
    },
  };
};

export const setObjectToList = (value) => {
  let listValue = [];
  Object.keys(value).forEach((key) => {
    if (value[key]) {
      listValue = [...listValue, { [key]: value[key] }];
    }
  });
  return listValue;
};

export const toCapitalize = (params) => {
  if (params?.length > 0 && params) {
    return params
      .trim()
      .toLowerCase()
      .replace('_', ' ')
      .replace(/\w\S*/g, (w) => {
        return w.replace(/^\w/, (c) => {
          return c.toUpperCase();
        });
      });
  }
  return undefined;
};

export const getUniqueArray = (params = []) => {
  const set = new Set(params);
  return Array.from(set);
};

export const copyToClipBoard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    return message.success('Text copied to clipboard');
  });
};

export const numberToStringFormatter = (number) => {
  if (number) {
    return String(number);
  }
  return null;
};

export const isEmptyAttributeObject = (value) => {
  const keys = Object.keys(value);

  return keys.reduce((ans, keyItem) => {
    const selectedAttribute = value[keyItem];

    const isEmptyString = selectedAttribute === '';
    const isUndefined = selectedAttribute === undefined;
    const isNull = selectedAttribute === null;
    const validate = isEmptyString || isUndefined || isNull;

    return ans || validate;
  }, false);
};

export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const isNotEmpty = (data) => {
  return data !== null && data !== undefined && data !== '';
};

export const inputNumberFormatter = (value) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const inputNumberParser = (value) => {
  return value.replace(/\$\s?|(\.*)/g, '');
};

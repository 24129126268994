import BaseService from '../baseServices';
import REST from '../../configs/rest';

function checkShippingService(body) {
  return BaseService.post(REST.CHECK_SHIPPING_SERVICE, body);
}

function shipOrderOrderPartner(body) {
  return BaseService.post(REST.SHIP_ORDER_PARTNER, body);
}

export default {
  checkShippingService,
  shipOrderOrderPartner,
};

import REST from '../../configs/rest';
import BaseService from '../baseServices';

function postProductGroupItem(body) {
  return BaseService.post(REST.PRODUCT_GROUP_ITEM, body);
}

function putProductGroupItem({ body, id }) {
  return BaseService.put(REST.PRODUCT_GROUP_ITEM_ID(id), body);
}

function deleteProductGroupItem(id) {
  return BaseService.delete(REST.PRODUCT_GROUP_ITEM_ID(id));
}

export default {
  postProductGroupItem,
  putProductGroupItem,
  deleteProductGroupItem,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListBanners(params) {
  return BaseService.get(REST.BANNER, {
    params: filterParams(params),
  });
}

function getBannerById(id) {
  return BaseService.get(REST.BANNER_ID(id));
}

function postBanner({ body }) {
  return BaseService.post(REST.BANNER, body);
}

function putBannerById({ id, body }) {
  return BaseService.put(REST.BANNER_ID(id), body);
}

function deleteBannerById(id) {
  return BaseService.delete(REST.BANNER_ID(id));
}

function postBannerTag({ body }) {
  return BaseService.post(REST.BANNER_TAG, body);
}

function deleteBannerTag({ body }) {
  return BaseService.delete(REST.BANNER_TAG, {
    data: body,
  });
}

export default {
  getBannerById,
  getListBanners,
  postBanner,
  putBannerById,
  deleteBannerById,
  postBannerTag,
  deleteBannerTag,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getProductRingDiamondShapes(params) {
  return BaseService.get(REST.PRODUCT_RING_DIAMOND_SHAPE, {
    params: filterParams(params),
  });
}

function putProductRingDiamondShape({ id, body }) {
  const payload = {
    product_id: id,
    ring_diamond_shapes: body,
  };

  return BaseService.put(REST.PRODUCT_RING_DIAMOND_SHAPE, payload);
}

function postProductRingDiamondShape(body) {
  return BaseService.post(REST.PRODUCT_RING_DIAMOND_SHAPE, body);
}

function deleteProductRingDiamondShape({ body, id }) {
  const payload = {
    product_id: id,
    ring_diamond_shapes: body,
  };

  return BaseService.delete(REST.PRODUCT_RING_DIAMOND_SHAPE, {
    data: payload,
  });
}

export default {
  getProductRingDiamondShapes,
  putProductRingDiamondShape,
  postProductRingDiamondShape,
  deleteProductRingDiamondShape,
};

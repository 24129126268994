import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getListVouchers(params) {
  return BaseService.get(REST.VOUCHER, {
    params: filterParams(params),
  });
}

function getVoucherById(id) {
  return BaseService.get(REST.VOUCHER_ID(id));
}

function putVoucherById({ id, body }) {
  return BaseService.put(REST.VOUCHER_ID(id), body);
}

function postVoucher(body) {
  return BaseService.post(REST.VOUCHER, body);
}

function deleteVoucher(id) {
  return BaseService.delete(REST.VOUCHER_ID(id));
}

function getVoucherCodeAvailability({ code }) {
  return BaseService.get(REST.VOUCHER_CODE_AVAILABILITY, {
    params: {
      code,
    },
  });
}

export default {
  getListVouchers,
  getVoucherById,
  putVoucherById,
  postVoucher,
  getVoucherCodeAvailability,
  deleteVoucher,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';
import { filterParams } from '../../utils/request';

function getProductTags(params) {
  return BaseService.get(REST.PRODUCT_TAG, {
    params: filterParams(params),
  });
}

function postProductTag(body) {
  return BaseService.post(REST.PRODUCT_TAG, body);
}

function deleteProductTag(body) {
  return BaseService.delete(REST.PRODUCT_TAG, {
    data: body,
  });
}

export default {
  getProductTags,
  postProductTag,
  deleteProductTag,
};

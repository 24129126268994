import BaseService from '../baseServices';
import REST from '../../configs/rest';

const uploadReq = (params = {}) => {
  const data = new FormData();
  data.append('file', params?.file);
  if (params?.customName) {
    data.append('name', params?.customName);
  }
  return BaseService.post(REST.UPLOAD_REQUEST, data);
};

const onCustomRequest = ({
  values,
  setIsUploading,
  setUploadData,
  customName,
}) => {
  if (setIsUploading) {
    setIsUploading(true);
  }
  const { file, onSuccess } = values;
  uploadReq({ file, customName })
    .then((res) => {
      if (setUploadData) {
        setUploadData(res);
      }
      onSuccess(res, file);
    })
    .finally(() => {
      if (setIsUploading) {
        setIsUploading(false);
      }
    });
};

export default {
  uploadReq,
  onCustomRequest,
};

import BaseService from '../baseServices';
import REST from '../../configs/rest';

function putProductTypeV2({ productId, body }) {
  return BaseService.put(REST.PRODUCT_V2_ADMIN_PRODUCT_TYPE(productId), body);
}

export default {
  putProductTypeV2,
};
